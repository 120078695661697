* {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  background-color: #0e1d31;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #f9f9f9;
}

.App {
  height: 100%;
  background-size: cover;
}

.all_genders {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;
}
.item_gender {
  display: flex;
  flex-direction: row;
  border: 1px solid #f9f9f9;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.link_mail {
  color: red;
  margin-left: auto;
  text-decoration: none;
}

.all_symbols {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 25px;
}
.item_symbol {
  border: 1px solid #f9f9f9;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.all_name {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.item_name {
  border: 1px solid #f9f9f9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  height: 40px;
  width: 100%;
  border-radius: 5px;
}
.Box {
  display: flex;
  flex-wrap: wrap;
}
.Box_Item {
  margin: 20px;
  display: flex;
  flex-direction: column;
  width: 200px;
  align-items: flex-start;
  justify-content: center;
  /* background-color: #f9f9f9; */
}
.label {
  width: 100%;
  text-align: center;
  /* background-color: #f9f9f9; */
}
.item_app {
  border: 1px solid #f9f9f9;
  border-radius: 20px;
  width: 200px;
}
.platform {
  width: 100%;
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  cursor: pointer;
}
